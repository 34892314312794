'use client';

import { BtnColorEnum, BtnSizeEnum, BtnVariantEnum } from '@features/api/client';
import { Button } from '@features/webshop/shared/ui/Button';
import { Result, Space } from 'antd';
import { $path } from 'next-typesafe-url';
import Link from 'next/link';

export default function Error({ reset }: { reset: () => void }) {
	return (
		<Result
			status="error"
			title="Oops! Nešto je pošlo po krivu."
			subTitle="Došlo je do greške. Pokušaj ponovno kroz nekoliko minuta, a mi ćemo u međuvremenu učiniti sve što možemo kako bi u najkraćem mogućem roku popravili problem."
			extra={
				<Link href={$path({ route: '/[[...path]]', routeParams: { path: undefined } })}>
					<Button
						size={BtnSizeEnum.Medium}
						color={BtnColorEnum.Blue}
						variant={BtnVariantEnum.Primary}>
						Idi na početnu
					</Button>
				</Link>
			}
		/>
	);
}
